<template>
  <div class="contato-view">
    <theContato />
  </div>
</template>

<script>
import TheContato from '../components/TheContato.vue';
export default {
  components: {
    TheContato,
  },
};
</script>

<style lang="scss">
.contato-view {
  width: 100%;
}
</style>
