<template>
  <div class="cobertura-holder">
    <div class="cobertura">
      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
      ></loading>
      <h2>ÁREA DE COBERTURA</h2>
      <div
        class="cobertura-content"
        v-if="Object.keys(cobertura).length && !isLoading"
      >
        <div class="bairros">
          <ul>
            <li v-for="item in cobertura" :key="item.id">
              {{ item.title }}
            </li>
          </ul>
        </div>
        <div>
          <div class="mapa">
            <img src="../assets/mapa.png" />
          </div>
        </div>
      </div>
      <div class="cobertura-atendimento">
        *Atendimento parcial, consultar disponibilidade para região.
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue3-loading-overlay';
export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      cobertura: {},
    };
  },
  methods: {
    async fetchCobertura() {
      this.isLoading = true;
      const cobertura = await this.$axios.get(`cobertura`).catch((e) => {
        console.error('Cobertura', e);
      });
      console.log(cobertura);
      this.cobertura = cobertura.data;
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
  },
  mounted() {
    this.fetchCobertura();
  },
};
</script>

<style lang="scss">
.cobertura-holder {
  width: 100%;
  background: rgb(249, 145, 56);
  background: radial-gradient(
    circle,
    rgba(249, 145, 56, 1) 0%,
    rgba(255, 114, 1, 1) 100%
  );
}

.cobertura {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  // background: rgb(249, 145, 56);
  // background: radial-gradient(
  //   circle,
  //   rgba(249, 145, 56, 1) 0%,
  //   rgba(255, 114, 1, 1) 100%
  // );
  font-family: 'Red Hat Display';
  font-size: var(--font-size--large);
  padding-top: 2rem;
  padding-bottom: 4rem;
  h2 {
    font-family: 'Red Hat Display Bold';
    font-size: var(--font-size--super-large);
    color: #fff;
    width: 27rem;
    line-height: 4.5rem;
    margin: 4rem;
  }
  &-content {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-family: 'Red Hat Display';
    margin-left: 2rem;

    .bairros {
      width: 80%;
    }

    ul {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
    }
    .mapa {
      position: relative;
      overflow: initial;
      height: 769px;
      width: 21rem;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-30%, -50%);
      }
    }
  }
  &-atendimento {
    color: #fff;
    display: flex;
    justify-content: center;
    padding: 0.25rem;
  }
  @media only screen and (max-width: 1830px) {
    &-content {
      .mapa {
        overflow: hidden;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    // font-size: 1.2rem;
    padding-top: 1.5rem;
    padding-bottom: 3rem;
    h2 {
      font-size: calc(var(--font-size--super-large) / 1.5);
      width: 24rem;
      line-height: 3.5rem;
      margin: 2rem;
    }
    &-content {
      margin-left: 1.5rem;

      .bairros {
        width: 100%;
      }

      ul {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
      }
      .mapa {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 620px) {
    &-content {
      ul {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
      }
    }
  }

  @media only screen and (max-width: 520px) {
    // font-size: 0.8rem;
    font-size: var(--font-size--default);
    padding-top: 1rem;
    padding-bottom: 2rem;
    h2 {
      font-size: calc(var(--font-size--super-large) / 1.5);

      width: 18rem;
      line-height: 2.5rem;
      margin: 1rem;
    }
    &-content {
      margin: 0.5rem;
    }
  }
}
</style>
