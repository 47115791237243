<template>
  <div class="vantagens-holder">
    <div class="vantagens">
      <div class="vantagens-fundo">
        <div class="vantagens-foguete">
          <img src="../assets/foguete.png" />
        </div>
        <div class="title">
          <div class="sub">Vantagens de ser cliente</div>
          <div class="principal">TOPNET-MS</div>
        </div>
        <div class="content">
          <div class="atendimento">
            <div class="cont">
              <div><img src="../assets/icons/centraldeatendimento.png" /></div>
              <div class="texto">
                <div class="a-title">Atendimento Humanizado</div>
                <div class="a-conteudo">
                  Contamos com um Atendimento rápido, atencioso e dinâmico,
                  pronto para te auxiliar em qualquer eventual dúvida.
                </div>
              </div>
            </div>
            <div class="cont">
              <div><img src="../assets/icons/centraldeatendimento.png" /></div>
              <div class="texto">
                <div class="a-title">Suporte técnico</div>
                <div class="a-conteudo">
                  Qualidade e agilidade de atendimento, com equipe treinada e
                  qualificada, para oferecer a melhor solução para você.
                </div>
              </div>
            </div>
          </div>
          <div class="garantia">
            <div class="cont">
              <div><img src="../assets/icons/velocimetro.png" /></div>
              <div class="texto">
                <div class="a-title">Ultra velocidade garantida</div>
                <div class="a-conteudo">
                  Possuímos tecnologia Fibra Optica de ultra velocidade,
                  <br />
                  garantindo ótimas experiências em navegação.
                </div>
              </div>
            </div>
          </div>
          <div class="sinal">
            <img src="../assets/sinalwifi.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.vantagens-holder {
  width: 100%;
  background: #0a2869;
}
.vantagens {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  font-family: 'Red Hat Display';
  background: rgb(15, 69, 143);
  background: linear-gradient(
    90deg,
    rgba(15, 69, 143, 1) 0%,
    rgba(19, 135, 218, 1) 20%,
    rgba(19, 135, 218, 1) 80%,
    rgba(15, 69, 143, 1) 100%
  );

  &-foguete {
    position: absolute;
    z-index: 1;
    left: -17rem;
    margin-top: -4rem;
  }
  &-fundo {
    background: url(../assets/fundo-estelar.png) no-repeat center center;
    background-size: cover;
  }
  .title {
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
    margin-bottom: 3rem;
    align-items: center;
    flex: 1;
    .sub {
      color: #fff;
      font-size: var(--font-size--super-large);
      font-family: 'Red Hat Display Bold';
      text-shadow: 4px 4px 7px #00000052;
    }
    .principal {
      font-family: 'Red Hat Display Black';
      color: #fff;
      font-size: calc(var(--font-size--extra-large) * 1.15);
      line-height: 4.5rem;
      text-shadow: 4px 4px 7px #00000052;
    }
  }
  .content {
    margin-left: 4rem;
    margin-right: 4rem;
    padding-bottom: 10rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    .atendimento {
      display: flex;
      margin-top: 4rem;
      .cont {
        display: flex;
        margin-bottom: 4rem;
        .texto {
          color: #fff;
          margin-left: 2rem;
          margin-right: 2rem;
          margin-top: 1.5rem;

          .a-title {
            font-family: 'Red Hat Display Bold';
            font-size: 2.5rem;
          }
          .a-conteudo {
            font-size: 1.4rem;
          }
        }
      }
    }

    .garantia {
      display: flex;
      .cont {
        display: flex;
        .texto {
          color: #fff;
          margin-left: 2rem;
          margin-right: 2rem;

          .a-title {
            font-family: 'Red Hat Display Bold';
            font-size: 2.5rem;
          }

          .a-conteudo {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  .sinal {
    position: absolute;
    right: 3rem;
    margin-top: 15rem;
  }

  @media only screen and (max-width: 1280px) {
    &-foguete {
      left: -22rem;
    }

    .title {
      .sub {
        font-size: calc(var(--font-size--large) * 1.5);
      }
      .principal {
        font-size: calc(var(--font-size--super-large) * 1.25);
        line-height: 4.5rem;
      }
    }
    .content {
      .atendimento,
      .garantia {
        .cont {
          margin-bottom: 4rem;
          align-items: center;
          .texto {
            margin-top: 1.5rem;
            .a-title {
              font-family: 'Red Hat Display Bold';
              font-size: 1.5rem;
            }
            .a-conteudo {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    .sinal {
      right: 1.5rem;
      top: 28rem;
      img {
        height: 6rem;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    &-foguete {
      left: -17rem;
      img {
        height: 25rem;
      }
    }

    .title {
      .sub {
        font-size: calc(var(--font-size--large) * 1.25);
      }
      .principal {
        font-size: var(--font-size--super-large);
        line-height: 3.5rem;
      }
    }
    .content {
      .atendimento,
      .garantia {
        .cont {
          margin-bottom: 2rem;
          .texto {
            .a-title {
              font-family: 'Red Hat Display Bold';
              font-size: 1.5rem;
            }
            .a-conteudo {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    .sinal {
      right: 2rem;
      top: 30rem;
      img {
        height: 5rem;
      }
    }
  }
  @media only screen and (max-width: 520px) {
    &-foguete,
    .sinal {
      display: none;
    }

    .title {
      .sub {
        font-size: 1.5rem;
      }
      .principal {
        font-size: 2.5rem;
        line-height: 3.5rem;
      }
    }
    .content {
      margin: 0;
      .atendimento,
      .garantia {
        flex-flow: column;
        .cont {
          margin-bottom: 4rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          .texto {
            .a-title {
              font-family: 'Red Hat Display Bold';
              font-size: 1.2rem;
            }
            .a-conteudo {
              font-size: 1rem;
            }
          }
        }
      }

      .garantia {
        // flex-direction: column;
        .texto {
          // .a-title {
          //   font-size: 1.2rem;
          // }

          .a-conteudo {
            // font-size: 1rem;
          }
        }
      }
    }
  }
}
</style>
