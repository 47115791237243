<template>
  <div class="duvidas-view">
    <theDuvidas />
  </div>
</template>

<script>
import TheDuvidas from '../components/TheDuvidas.vue';
export default {
  components: {
    TheDuvidas,
  },
};
</script>

<style lang="scss">
.duvidas-view {
  width: 100%;
}
</style>
