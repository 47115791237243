<template>
  <div class="home">
    <slider />
    <planos-small />
    <banner />
    <!-- <empresa /> -->
    <!-- <vantagens /> -->
    <!-- <cobertura /> -->
    <contrato />
    <!-- <contato /> -->
  </div>
  <!-- <div class="custom-footer">
    <theFooter />
  </div> -->
</template>

<script>
import slider from '@/components/TheSlider.vue';
// import planos from '@/components/ThePlanos.vue';
import banner from '@/components/TheBanner.vue';
import planosSmall from '../components/ThePlanosSmall.vue';
// import empresa from '@/components/TheEmpresa.vue';
// import vantagens from '@/components/TheVantagens.vue';
// import cobertura from '@/components/TheCobertura.vue';
// import contato from '@/components/TheContato.vue';
// import theFooter from '@/components/TheFooter.vue';
import contrato from '@/components/TheContrato.vue';

export default {
  name: 'HomeView',
  components: {
    slider,
    banner,
    planosSmall,
    contrato,
    // theFooter,
  },
};
</script>

<style lang="scss">
.home {
  width: 100%;
}
</style>
