<template>
  <div class="contrato-holder">
    <div class="contrato">
      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
      ></loading>
      <div
        class="contrato-content"
        v-if="Object.keys(contratos).length && !isLoading"
      >
        <div class="title">
          <h2>Contrato</h2>
        </div>
        <div class="contrato-links">
          <ul>
            <li v-for="item in contratos" :key="item.id">
              <a :href="item.arquivo" target="_blank">{{ item.title }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue3-loading-overlay';
export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      contratos: {},
    };
  },
  methods: {
    async fetchContratos() {
      this.isLoading = true;
      const contratos = await this.$axios.get(`contratos`).catch((e) => {
        console.error('Contratos', e);
      });
      console.log(contratos);
      this.contratos = contratos.data;
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
  },
  mounted() {
    this.fetchContratos();
  },
};
</script>

<style lang="scss">
.contrato-holder {
  width: 100%;
  background-color: #134590;
}
.contrato {
  max-width: 1440px;
  margin: 0 auto;
  padding: 2rem 0 4rem 0;
  background-color: #134590;
  h2 {
    margin: 0;
    text-align: center;
    color: #ffffff;
    font-family: 'Red Hat Display Bold';
    font-size: var(--font-size--super-large);
  }

  &-links {
    text-align: center;
    ul {
      list-style: none;
      li {
        margin-bottom: 1rem;
      }
      a {
        text-decoration: none;
        color: #fff;
        padding: 20px 20px 80px 20px;
        font-family: 'Red Hat Display';
        font-size: 1.6rem;
      }
    }
  }
  @media only screen and (max-width: 1280px) {
  }
  @media only screen and (max-width: 768px) {
    h2 {
      font-size: var(--font-size--super-large);
    }
  }
  @media only screen and (max-width: 520px) {
    h2 {
      font-size: calc(var(--font-size--super-large) / 1.5);
    }
    &-links {
      text-align: center;
      ul {
        padding: 0;
        a {
          font-size: 1.2rem;
        }
      }
    }
  }
}
</style>
