<script setup>
// import { RouterLink, RouterView } from "vue-router";
import NavigationBar from '@/components/NavigationBar.vue';
// import BannerTop from './components/BannerTop.vue';
</script>

<template>
  <!-- <banner-top /> -->
  <div class="app-content" id="app-content">
    <NavigationBar />
    <router-view />
    <back-to-top
      bottom="7px"
      right="10px"
      visibleoffset="200"
      class="btn-to-top"
    >
      <i class="fa fa-chevron-up"></i>
    </back-to-top>
    <back-to-top
      bottom="158px"
      right="10px"
      visibleoffset="1"
      class="whats-to-top"
    >
      <a href="https://wa.me/5567999054865" target="_blank"
        ><img :src="whats" alt="" />
      </a>
    </back-to-top>

    <div class="custom-footer">
      <theFooter />
    </div>
  </div>
</template>

<script>
import theFooter from '@/components/TheFooter.vue';
import '@fortawesome/fontawesome-free/css/all.css';
import BackToTop from 'vue-backtotop';
const whats = new URL('./assets/icons/whatsapp-logo.svg', import.meta.url).href;

export default {
  name: 'app',
  components: {
    BackToTop,
    theFooter,
  },
  data() {
    return {
      whats: whats,
    };
  },
};
</script>

<style lang="scss">
@import '@/assets/main.css';

.btn-to-top {
  width: 60px;
  height: 60px;
  padding: 10px 16px;
  border-radius: 50%;
  font-size: 22px;
  line-height: 22px;
  background-color: #134590;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whats-to-top {
  img {
    width: 64px;
    height: 64px;
    position: relative;
    z-index: 2;
  }

  .whats-text {
    color: white;
    font-size: 11px;
    font-weight: bold;
    position: absolute;
    top: 44%;
    right: -50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 4px 35px 4px 25px;
    border-radius: 10px;
    background-color: #25d366;
  }
}

.app-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.custom-footer {
  margin-top: auto;
}

@media only screen and (min-width: 993px) {
  .whats-to-top {
    display: none;
  }
}
</style>
