<template>
  <div class="empresa-view">
    <theEmpresa />
    <theVantagens />
  </div>
</template>

<script>
import TheEmpresa from '../components/TheEmpresa.vue';
import TheVantagens from '../components/TheVantagens';

export default {
  components: {
    TheEmpresa,
    TheVantagens,
  },
};
</script>

<style lang="scss">
.empresa-view {
  width: 100%;
}
</style>
