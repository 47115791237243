<template>
  <div class="planos-small-holder">
    <div class="planos-small">
      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
      ></loading>
      <!-- <h2 class="planos-small-title">Escolha o melhor Plano para você!</h2> -->
      <ul
        class="planos-small-categoria"
        v-if="Object.keys(planos).length && !isLoading"
      >
        <li
          class="planos-small-categoria-item"
          v-for="plano in planos"
          :key="plano.id"
        >
          <div class="planos-small-sale" v-if="plano.destaque === '1'">
            <img :src="trofeu" alt="" class="trofeu-icon" />
            <div class="planos-small-sale-text">Mais Vendido</div>
          </div>
          <div v-else class="planos-small-height"></div>
          <div class="price-holder">
            <div class="planos-small-size">
              <div class="planos-small-size-number">{{ plano.title }}</div>
              <div class="planos-small-size-number-add" v-if="plano.adicional">
                +{{ plano.adicional }}
              </div>
              <div class="planos-small-size-type">Mega</div>
            </div>

            <div class="planos-small-price">
              <div class="flex">
                de&nbsp;
                <div class="ant">{{ plano.valor_antigo }}</div>
              </div>
              <div>
                <!-- <div class="planos-small-price-symbol">R$</div> -->
                <div class="planos-small-price-value">
                  {{ plano.inteiro
                  }}<span class="super">,{{ plano.centavos }}*</span>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="planos-small-updown">
            <div class="planos-small-download">
              <div><img :src="download" alt="" class="icon" /></div>
              <div>Download {{ plano.upAndDown[0] }}</div>
            </div>
            <div class="planos-small-upload">
              <div><img :src="upload" alt="" class="icon" /></div>
              <div>Upload {{ plano.upAndDown[1] }}</div>
            </div>
          </div>
          <div class="planos-small-radiotv">
            <div class="planos-small-tv">
              <div><img :src="tv" alt="" class="icon" /></div>
              <div>mais de 8000 <br />filmes</div>
            </div>
            <div class="planos-small-radio">
              <div><img :src="radio" alt="" class="icon" /></div>
              <div>Canais locais/<br />Fechados**, <br />Radios locais</div>
            </div>
          </div>
          <div class="planos-small-contratar">
            <a href="#" @click.prevent="sendPlano(plano)"
              >Contratar esse plano!</a
            >
          </div>
        </li>
      </ul>
      <div class="planos-small-detalhes">
        <div class="planos-small-detalhes-title">
          Abaixo, listamos todas as características que são padrão para todos os
          nossos planos:
        </div>
        <div class="planos-small-detalhes-body">
          * IPv4 dinâmico (CGNAT) | * Suporte em até 48 Horas úteis |* A
          velocidade anunciada de acesso e tráfego na internet é a máxima
          nominal, podendo sofrer variações decorrentes de fatores externos |*
          Os equipamentos são cedidos em regime de comodato * A viabilidade e
          velocidade estão sujeitas a verificação no ato da instalação |* Com
          fidelidade de 12 meses |* Sem franquia de consumo (tráfego ilimitado).
        </div>
      </div>
      <vue-final-modal
        v-model="showModal"
        classes="modal-container"
        content-class="modal-content"
        :esc-to-close="true"
        @before-close="resetForm"
      >
        <span class="modal__close" @click="closeModal">
          <img :src="close" alt="" />
        </span>
        <div class="modal__title">
          <h6>Plano selecionado: {{ selectedPlano.title }} Mega</h6>
        </div>

        <div class="modal__content">
          <form class="form-contato" @submit.prevent="onSubmit">
            <input v-model="form.name" placeholder="Nome" required />
            <input
              v-model="form.phone"
              type="tel"
              v-mask="['(##)####-####', '(##)#####-####']"
              placeholder="Telefone / Whatsapp"
              pattern=".{13,15}"
            />
            <!-- <input
              v-model="form.phone2"
              type="tel"
              v-mask="['(##)####-####', '(##)#####-####']"
              placeholder="Telefone 2"
              pattern=".{13,15}"
            />
            <input
              v-model="form.whats"
              type="tel"
              v-mask="['(##)####-####', '(##)#####-####']"
              placeholder="Whatsapp"
              pattern=".{13,15}"
            /> -->
            <input v-model="form.mail" type="email" placeholder="E-mail" />
            <textarea
              v-model="form.address"
              placeholder="Endereço"
              cols="4"
              maxlength="2000"
            ></textarea>

            <p class="alert" v-if="message.length">
              <span
                class="closebtn"
                onclick="this.parentElement.style.display='none';"
                >&times;</span
              >
              {{ message }}
            </p>
            <div class="enviar">
              <button :disabled="isDisabled" type="submit" value="Submit">
                Enviar
              </button>
            </div>
          </form>
        </div>
      </vue-final-modal>
    </div>
  </div>
</template>

<script>
import Loading from 'vue3-loading-overlay';

import { VueFinalModal } from 'vue-final-modal';
import { mask } from 'vue-the-mask';
const download = new URL('../assets/icons/download.png', import.meta.url).href;
const upload = new URL('../assets/icons/upload.png', import.meta.url).href;
const tv = new URL('../assets/icons/tv.png', import.meta.url).href;
const radio = new URL('../assets/icons/radio.png', import.meta.url).href;
const trofeu = new URL('../assets/icons/trofeu.png', import.meta.url).href;
const close = new URL('../assets/icons/close-thick.png', import.meta.url).href;

export default {
  directives: { mask },
  components: {
    VueFinalModal,
    Loading,
  },
  data() {
    return {
      download,
      upload,
      trofeu,
      tv,
      radio,
      close,
      showModal: false,
      isLoading: false,
      isSending: false,
      planos: {},
      selectedPlano: {
        id: '',
        title: '',
      },
      form: {
        name: '',
        phone: '',
        mail: '',
        address: '',
      },
      message: '',
    };
  },
  computed: {
    isDisabled: function () {
      const mandatory = this.form.name.length > 3 && this.form.phone.length > 0;
      return !mandatory || this.isSending;
    },
  },
  methods: {
    resetForm() {
      this.form = {
        name: '',
        phone: '',
        mail: '',
        address: '',
      };
    },
    closeModal() {
      this.resetForm();
      this.showModal = false;
    },
    onSubmit(event) {
      event.preventDefault();

      if (this.form.phone == '') {
        this.message =
          'É necessário preencher pelo menos um número de telefone.';
        return false;
      } else {
        this.message = '';
      }
      this.sendForm();
    },
    async sendForm() {
      this.isSending = true;
      const res = await this.$axios
        .post('assine', {
          ver: '2',
          planos_id: this.selectedPlano.id,
          nome: this.form.name,
          telefone1: this.form.phone,
          endereco: this.form.address,
          email: this.form.mail,
        })
        .catch((e) => {
          console.error('Assine', e);
          this.$toast.warning(`Algo deu errado, por favor entre em contato.`);
          setTimeout(this.$toast.clear, 3000);
        });
      console.log(res);

      if (res.data.id) {
        this.$toast.success(`Solicitação enviada com sucesso!`);
        setTimeout(this.$toast.clear, 3000);
      }
      this.isSending = false;
      this.closeModal();
    },
    async fetchPlanos() {
      this.isLoading = true;
      const planos = await this.$axios.get(`planos`).catch((e) => {
        console.error('Planos', e);
      });
      for (const item in planos.data) {
        const value = parseFloat(planos.data[item].value).toFixed(2);
        const beneficios = planos.data[item]['beneficios'].split(',');
        planos.data[item]['inteiro'] = value.match(/\d+/)[0];
        planos.data[item]['centavos'] = /\.(\d{2})/.exec(value)[1];
        planos.data[item]['upAndDown'] = beneficios;
      }
      this.planos = planos?.data;

      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    sendPlano(plano) {
      this.selectedPlano = plano;
      this.showModal = true;
    },
  },
  mounted() {
    this.fetchPlanos();
  },
};
</script>

<style lang="scss">
@import 'node_modules/vue3-loading-overlay/dist/vue3-loading-overlay';
.planos-small-holder {
  width: 100%;
  padding-top: 20px;
  background-color: #fe7c0a;
}
.planos-small {
  position: relative;
  background-color: #fe7c0a;
  max-width: 1440px;
  margin: 0 auto;
  font-family: 'Red Hat Display';

  &-title {
    color: #fff;
    font-family: 'Red Hat Display Bold';
    display: flex;
    justify-content: center;
    // padding: 1rem;
    font-size: calc(var(--font-size--large) * 1.5);
    margin: 0;
    text-align: center;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 20px auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
    li {
      background: #ffffff;
      border-radius: 10px;
      padding: 0;
      margin: 0 10px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .price-holder {
    display: flex;
  }

  &-size {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &-number {
      font-family: 'Kanit Bold';
      font-size: 48px;
      line-height: 57px;
      color: #134590;
      padding-right: 10px;
      margin-left: -25px;
    }
    &-number-add {
      font-family: 'Kanit Bold';
      font-size: 38px;
      line-height: 57px;
      color: #134590;
      padding-right: 5px;
    }
    &-type {
      font-family: 'Red Hat Display Bold';
      font-size: 16px;
      color: #ff7f1c;
      // transform-origin: 0 0;
      transform: rotate(-90deg);
      position: absolute;
      top: 19px;
      right: -25px;
      text-transform: uppercase;
    }
  }

  hr {
    border: none;
    border-top: 3px solid #ff7f1c;
    margin: 3px;
    width: 80%;
  }

  &-price {
    font-family: 'Kanit Bold';
    color: #134590;
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-left: 25px;
    &-symbol {
      font-family: 'Red Hat Display Bold';
      font-size: 24px;
    }
    &-value {
      font-size: 36px;
      line-height: 54px;
      position: relative;
      .cents {
        font-size: 16px;
      }
      .super {
        position: absolute;
        font-size: 16px;
        bottom: 5px;
      }
    }
  }

  &-height {
    height: 24px;
  }

  &-sale {
    display: flex;
    font-family: 'Red Hat Display Bold';
    background-color: #f1a109;
    box-shadow: 1px 1px 1px #ff7f1c;
    color: #fff;
    padding: 3px;
    margin: 2px 10px 0;
    border-radius: 5px;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    width: fit-content;

    .trofeu-icon {
      height: 1rem;
      margin-right: 5px;
      color: #fff;
      padding-left: 1rem;
    }
    &-text {
      padding-right: 1rem;
    }
  }
  &-updown,
  &-radiotv {
    padding: 5px 0;
    display: flex;
    align-items: space-between;
  }

  &-download,
  &-upload,
  &-radio,
  &-tv {
    display: flex;
    align-items: center;
    font-family: 'Red Hat Display Bold';
    font-size: 14px;
    color: #000000;
    padding-right: 10px;

    .icon {
      height: 20px;
      margin-right: 5px;
    }
  }

  &-radio,
  &-tv {
    .icon {
      height: 25px;
    }
  }

  &-contratar {
    background-color: #153d82;
    width: 100%;
    padding: 8px;
    border-radius: 0 0 10px 10px;
    text-align: center;
    a {
      text-decoration: none;
      color: #fff;
      padding: 10px;
      border-radius: 5px;
      font-size: 15px;
      font-family: 'Red Hat Display Bold';
      &:hover {
        opacity: 0.75;
      }
    }
  }

  &-detalhes {
    color: #fff;
    text-align: center;
    padding: 0px 20px 80px 10px;

    &-title {
      font-family: 'Red Hat Display Bold';
      font-size: 16px;
    }
    &-body {
      font-family: 'Red Hat Display';
      font-size: 14px;
      padding: 5px 20px;
    }
  }

  // @media only screen and (max-width: 1280px) {
  //   &-title {
  //     font-size: calc(var(--font-size--super-large) / 1.2);
  //   }

  //   // ul {
  //   //   li {
  //   //     padding: 1.2rem 1rem;
  //   //     margin: 1rem 1rem;
  //   //   }
  //   // }

  //   &-size {
  //     &-number {
  //       font-size: 85px;
  //       line-height: 92px;
  //     }
  //     &-type {
  //       font-size: 20px;
  //       right: -40px;
  //     }
  //   }

  //   &-price {
  //     &-symbol {
  //       font-size: 20px;
  //     }
  //     &-value {
  //       font-size: 60px;
  //       line-height: 50px;

  //       .cents {
  //         font-size: 30p;
  //       }
  //       .super {
  //         position: absolute;
  //         font-size: 22px;
  //         bottom: 0.5rem;
  //       }
  //     }
  //   }

  //   &-height {
  //     height: 2.4rem;
  //   }

  //   &-sale {
  //     font-size: 12.5px;
  //   }
  //   &-updown {
  //     padding: 20px;
  //   }

  //   // &-download,
  //   // &-upload {
  //   //   font-size: 12px;
  //   // }

  //   &-contratar {
  //     &:link {
  //       font-size: 15px;
  //     }
  //   }

  //   &-detalhes {
  //     &-title {
  //       font-size: 18px;
  //     }
  //     &-body {
  //       font-size: 16px;
  //     }
  //   }
  // }

  // @media only screen and (max-width: 768px) {
  //   // ul {
  //   //   li {
  //   //     padding: 1.2rem 2rem;
  //   //     margin: 1rem 1rem;
  //   //   }
  //   // }
  //   // &-contratar {
  //   //   &:link {
  //   //     font-size: 10px;
  //   //   }
  //   // }

  //   // &-size {
  //   //   &-number {
  //   //     font-size: 75px;
  //   //     line-height: 82px;
  //   //   }
  //   //   &-type {
  //   //     font-size: 15px;
  //   //     right: -45px;
  //   //     top: 50px;
  //   //   }
  //   // }

  //   // &-price {
  //   //   &-symbol {
  //   //     font-size: 15px;
  //   //   }
  //   //   &-value {
  //   //     font-size: 50px;
  //   //     line-height: 40px;

  //   //     .cents {
  //   //       font-size: 30px;
  //   //     }
  //   //     .super {
  //   //       position: absolute;
  //   //       font-size: 22px;
  //   //       bottom: 0.5rem;
  //   //     }
  //   //   }
  //   // }

  //   // &-detalhes {
  //   //   &-title {
  //   //     font-size: 14px;
  //   //   }
  //   //   &-body {
  //   //     font-size: 12px;
  //   //   }
  //   // }
  // }

  // @media only screen and (max-width: 520px) {
  //   &-title {
  //     font-size: calc(var(--font-size--large) * 1.65);
  //   }
  //   &-height {
  //     height: 0.25rem;
  //   }
  // }
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
}

.modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 80%;
  max-height: 95%;
  overflow-y: auto;
}

.modal__content {
  .form-contato {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      font-family: 'Red Hat Display';
      background-color: #0b4d9b;
      height: 4rem;
      width: 80%;
      margin-bottom: 1rem;
      border: 0;
      font-size: 15px;
      color: #fff;
      padding-left: 1rem;
      padding-right: 1rem;
      box-sizing: border-box;
      border-radius: 5px;
      &::placeholder {
        color: #e7dddd;
      }
    }

    textarea {
      font-family: 'Red Hat Display';
      background-color: #0b4d9b;
      height: 6rem;
      width: 80%;
      margin-bottom: 1rem;
      border: 0;
      font-size: 15px;
      color: #fff;
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 5px;
      box-sizing: border-box;

      &::placeholder {
        color: #e7dddd;
      }
    }

    .enviar {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1rem;
      width: 80%;
      button {
        background-color: #0b4d9b;
        height: 4rem;
        width: 20rem;
        font-size: 15px;
        color: #e7dddd;
        border: 0;
        border-radius: 5px;

        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }

        button:disabled,
        &[disabled] {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }

    @media only screen and (max-width: 520px) {
      input {
        height: 2rem;
        width: 90%;
        margin-bottom: 0.5rem;
        border: 0;
        font-size: 10px;
      }
      textarea {
        height: 4rem;
        width: 90%;
        margin-bottom: 0.5rem;
        border: 0;
        font-size: 10px;
      }

      .enviar {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;
        width: 90%;
        button {
          height: 2rem;
          width: 20rem;
          font-size: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 520px) {
  .planos-small ul {
    grid-template-columns: repeat(1, 1fr);
  }
}

.modal__title {
  font-family: 'Red Hat Display Bold';
  font-size: 20px;
  color: #0b4d9b;
  font-weight: 700;
  text-align: center;
  h6 {
    margin: 0 0 1rem 0;
  }
}

.modal__close {
  display: flex;
  justify-content: end;
  cursor: pointer;
}

/* The alert message box */
.alert {
  padding: 5px;
  background-color: #fe7c0a;
  color: white;
  margin: 10px 0;
  opacity: 1;
  transition: opacity 0.6s;
  border-radius: 5px;
}

/* The close button */
.closebtn {
  margin-left: 10px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 12px;
  line-height: 10px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: black;
}
.ant {
  text-decoration: line-through;
}
.flex {
  display: flex;
}
</style>
