<template>
  <div class="banner-holder">
    <div class="banner">
      <div class="pipoca1"><img src="../assets/pipoca.png" /></div>
      <div class="content">
        Além de uma conexão de ultra velocidade,<br />
        contrate um plano e ganhe de bônus APP <br />de canais e filmes em HD
        para celular.
      </div>
      <div class="pipoca2"><img src="../assets/pipoca.png" /></div>
      <div class="mao"><img src="../assets/mao.png" /></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.banner-holder {
  width: 100%;
  background-color: #144e9b;
}
.banner {
  max-width: 1440px;
  margin: 0 auto;
  font-family: 'Red Hat Display';
  background: url(../assets/fundo-tv.jpg) no-repeat center center;
  background-size: cover;
  padding: 8rem 4rem 8rem 4rem;
  position: relative;
  .content {
    padding-left: 4rem;
    color: #fff;
    font-size: var(--font-size--super-large);
    line-height: 44px;
    text-shadow: 4px 4px 7px #00000052;
  }
  .pipoca1 {
    position: absolute;
    margin-top: -10rem;
    left: -10rem;
  }
  .pipoca2 {
    position: absolute;
    right: 5rem;
    margin-top: -16.9rem;
    img {
      height: 14rem;
    }
  }

  .mao {
    position: absolute;
    right: 5rem;
    margin-top: -10.9rem;
  }

  @media only screen and (max-width: 1280px) {
    .content {
      font-size: calc(var(--font-size--super-large) / 1.5);
      line-height: 38px;
    }

    .mao {
      right: 3rem;
    }
  }
  @media only screen and (max-width: 768px) {
    .content {
      padding-left: 0;
      font-size: calc(var(--font-size--super-large) / 1.75);
      line-height: 34px;
    }

    .pipoca2 {
      margin-top: -14.9rem;
      img {
        height: 13rem;
      }
    }

    .mao {
      right: 1rem;
    }
  }
  @media only screen and (max-width: 520px) {
    .content {
      text-align: center;
    }
    .pipoca1,
    .pipoca2,
    .mao {
      display: none;
    }
  }
}
</style>
