<template>
  <div class="duvidas-holder">
    <div class="duvidas">
      <h2>Dúvidas</h2>
      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
      ></loading>
      <div class="duvidas-content">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item
            :title="item.pergunta"
            :name="item.id"
            v-for="item of duvidas"
            :key="item.id"
            class="title"
          >
            <div>
              {{ item.resposta }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const activeName = ref('1');
</script>

<script>
import Loading from 'vue3-loading-overlay';

export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      duvidas: {},
    };
  },
  methods: {
    async fetchDestaque() {
      this.isLoading = true;
      const duvidas = await this.$axios.get(`duvidas`).catch((e) => {
        console.error('Duvidas', e);
      });
      this.duvidas = duvidas.data;
      // this.duvidas.map((obj) => ({ ...obj, expanded: false }));
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
  },
  mounted() {
    this.fetchDestaque();
  },
};
</script>

<style lang="scss">
.duvidas-holder {
  max-width: 100%;
}
.duvidas {
  position: relative;
  min-height: 300px;
  max-width: 1440px;
  margin: 4rem auto 0;

  h2 {
    margin: 10px 0;
    text-align: center;
    color: #134490;
    font-family: 'Red Hat Display Bold';
    font-size: calc(var(--font-size--super-large));
  }

  & > .vld-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &-content {
    padding: 0 2rem 4rem;
  }

  .el-collapse-item__header {
    background: #ff7f1c;
    color: white;
    font-size: var(--font-size--default);
    padding: 20px;
  }
  .el-collapse-item__content {
    padding: 20px;
    font-size: var(--font-size--default);
  }

  .el-collapse-item {
    border-radius: 5px;
    border: solid 2px #ff7f1c;
    margin-top: 10px;
  }

  .el-collapse-item__header,
  .el-collapse-item__wrap {
    border-bottom: none;
  }

  .el-collapse-item:last-child {
    margin-bottom: none;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 1024px;
  }

  @media only screen and (max-width: 520px) {
    h2 {
      font-size: calc(var(--font-size--super-large) / 1.5);
    }
  }
}
</style>
