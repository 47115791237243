<template>
  <div class="cobertura-view">
    <theCobertura />
  </div>
</template>

<script>
import TheCobertura from '../components/TheCobertura.vue';
export default {
  components: {
    TheCobertura,
  },
};
</script>

<style lang="scss">
.cobertura-view {
  width: 100%;
}
</style>
