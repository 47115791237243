import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import router from './router';
import toaster from '@meforma/vue-toaster';
import VueCookieComply from 'vue-cookie-comply';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'vue-cookie-comply/dist/style.css';

const app = createApp(App);

app.use(router);
app.use(toaster);
app.use(VueCookieComply);
app.use(ElementPlus);

const $axios = axios.create({
  baseURL: 'https://api.topnetms.com.br/',
});

app.config.globalProperties.$axios = { ...$axios };

app.mount('#app');
