import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import DuvidasView from '../views/DuvidasView.vue';
import ContatoView from '../views/ContatoView.vue';
// import PlanosView from '../views/PlanosView.vue';
import CoberturaView from '../views/CoberturaView.vue';
import EmpresaView from '../views/EmpresaView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },

  {
    path: '/duvidas',
    name: 'duvidas',
    component: DuvidasView,
  },
  {
    path: '/contato',
    name: 'contato',
    component: ContatoView,
  },
  // {
  //   path: '/planos',
  //   name: 'planos',
  //   component: PlanosView,
  // },
  {
    path: '/cobertura',
    name: 'cobertura',
    component: CoberturaView,
  },
  {
    path: '/empresa',
    name: 'empresa',
    component: EmpresaView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
